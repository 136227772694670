.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact header {
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid white */
}

.btn {
  /* fixed width */
  width: 200px;
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  margin: 40px 0;
  color: #03e9f4;
  font-size: 24px;
  text-decoration: none;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, #0005); */
}

/* .btn::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: #03e9f4;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0.7;
} */

.btn:nth-child(1) {
  filter: hue-rotate(190deg);
}

.btn:nth-child(3) {
  filter: hue-rotate(110deg);
}

.btn:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
}

.btn span {
  position: absolute;
  display: block;
}

.btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: animate1 10s linear infinite;
}
/* @keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
} */

.btn span:nth-child(2) {
  top: -100%;
  right: 0%;
  width: 5px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
/* @keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
} */

.btn span:nth-child(3) {
  bottom: 0%;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
/* @keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
} */

.btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 5px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
/* @keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
} */

.headingUnderline {
  width: 75px;
  height: 2px;
  margin-top: 15px;
  background-color: #f1b630;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text {
  padding-top: 10px;
}
.contact h1 {
  font-size: 40px;
}
@media only screen and (min-width: 760px) {
  .contact h1 {
    font-size: 55px;
  }

  .btn {
    width: 350px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .contact {
    justify-content: space-around;
    text-align: center;
    height: 100vh;
  }

  .contact p {
    font-size: 20px;
  }

  .btnContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
