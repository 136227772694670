.techList {
  display: flex;
  flex-wrap: wrap; /* Allow tags to wrap naturally */
  gap: 15px; /* Consistent spacing between items */
  margin: 0; /* Reset default paragraph margin */
}

.techEl {
  color: #fdb100;
  border: 1.3px solid #fdb100;
  border-radius: 5px;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
}

/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .imgContainer {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .imgContainer {
    padding: 30px 0;
  }
}

.aboutContainer main {
  padding: 2rem 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 90%;
}

.aboutContainer h2 {
  text-align: center;
  margin-top: 0%;
  padding-top: 8%;
}

.positionContainer {
  border: 0.5px solid transparent; /* Reserve space with transparent border */
  padding: 0.5rem 2.5rem;
  background-color: #353535;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.positionContainer:hover {
  cursor: pointer;
}

.positionContainer p {
  text-align: justify;
}

.positionContainer:hover {
  transform: translate3d(0, -15px, 20px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  background-color: #424242;
}

.textContainer {
  color: var(--very-pale-yellow);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%; /* Ensure it doesn't overflow */
  max-width: 100%;
}

.textContainer h3 {
  text-align: left;
  font-size: 1.4em;
}

.arrowContainer {
  position: relative;
  top: 5px;
  left: 5px;
  transition: top 0.1s;
}

.positionContainer:hover .textHeading {
  color: var(--deep-yellow);
}

.positionContainer:hover .arrowContainer {
  top: -2px;
}

.imgContainer {
  text-align: center;
}

.imgContainer img {
  width: 60%;
  border-radius: 50%;
}

@media only screen and (min-width: 1025px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    /* addition below ...  */
    height: 100vh;
  }

  .aboutContainer main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
  }

  .aboutContainer h2 {
    margin-top: 0%;
    padding: 3% 0;
  }

  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .imgContainer img {
    width: 70%;
  }
}
