/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .text {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .text {
    padding-bottom: 20px;
    /* border: 1px solid black; */
  }
}

@media only screen and (max-width: 759px) {
  .skillContainer {
    row-gap: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
  }

  .headingUnderline {
    margin-top: 15px;
    width: 75px;
    height: 2px;
    background-color: var(--deep-yellow);
  }

  .text {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
    row-gap: 20px;
  }

  .skillContainer section {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .details {
    padding: 0 20%;
    border-left: 2px solid var(--less-deep-dark);
  }

  .text h3 {
    color: var(--deep-yellow);
  }
}

@media only screen and (min-width: 760px) and (max-width: 1024px) {
  .skillContainer {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    /* justify-content: center; */
  }

  .skillContainer h1 {
    font-size: 3rem;
  }

  .text h3 {
    font-size: 2rem;
  }

  .skillContainer header {
    padding: 5% 0 10% 0;
  }
  .headingUnderline {
    margin-top: 15px;
    width: 75px;
    height: 2px;
    background-color: var(--deep-yellow);
  }

  .text {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
    row-gap: 20px;
  }

  .skillContainer section {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .details {
    padding: 0 20%;
    border-left: 2px solid var(--less-deep-dark);
  }

  .text h3 {
    color: var(--deep-yellow);
  }
}

@media only screen and (min-width: 1025px) {
  .skillContainer {
    display: grid;
    grid-template-rows: 1fr 4fr;
    padding: 0 5%;
    height: 100vh;
  }

  .skillContainer h1 {
    font-size: 50px;
  }

  .skillContainer h1 {
    width: 5%;
    padding-bottom: 2%;
    border-bottom: 2px solid var(--deep-yellow);
  }

  .text {
    font-size: 38px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3%;
  }

  .text section {
    display: flex;
    flex-direction: column;
  }

  .details {
    border-top: 2px solid var(--less-deep-dark);
    font-size: 22px;
    display: flex;
    flex-direction: column;
  }
  .text h3 {
    color: var(--deep-yellow);
  }
}
